<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <!-- <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="城市">
            <el-input
              v-model="searchInfo.number"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item class="right-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in tableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="getItemDetail(scope.row)" class="update-btn"
              >查看详情</span
            ></template
          >
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div> -->
      <!-- <Pagination -->
      <!-- :total="dataTotal" -->
      <!-- :page="searchInfo.page" -->
      <!-- :limit="searchInfo.limit" -->
      <!-- @pagination="onChangePages" -->
      <!-- /> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
// import Pagination from "@/components/Pagination";
// import { getTableListFun } from "../../../api/logadming";
import { getPrivateList } from "../../../api/common";

export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      // 查询数据
      searchInfo: {
        time: "",
        number: "",
        // page: 1,
        // size: 10,
      },
      lastTime: "",
      // 表格数据
      tableData: [],
      // 表格标题数组
      tableTitle: [
        { name: "ID号", code: "id", width: "" },
        { name: "所在城市", code: "city", width: "" },
        { name: "车型", code: "model", width: "" },
        { name: "渠道", code: "supplierName", width: "" },
        { name: "上牌时间", code: "registrationTime", width: "" },
        { name: "手机号码", code: "phone", width: "" },
        { name: "线索下发时间", code: "uploadTime", width: "" },
        { name: "首次联系时间", code: "firstContactTime", width: "" },
        { name: "联系状态", code: "contactStateVal", width: "" },
      ],
      // 数据总数
      dataTotal: 100,
    };
  },
  mounted() {
    this.onGetDataList();
  },
  methods: {
    // 查询
    onSearch() {
      // this.searchInfo.page = 1;
      this.onGetDataList();
    },
    // 重置
    onReset() {
      this.searchInfo.time = "";
      this.searchInfo.number = "";
      this.onSearch();
    },
    // 分页方法
    // onChangePages(pageInfo) {
    // const { page, limit } = pageInfo;
    // this.searchInfo.page = page;
    // this.searchInfo.size = limit;
    // this.onGetDataList();
    // },
    // 核心查询数据方法
    onGetDataList() {
      // 查询数据方法
      getPrivateList().then((res) => {
        this.tableData = res.data.queryResult.list;
        this.tableData.forEach((item) => {
          item.supplierName =
            item.supplier == "sinopec"
              ? "雷峰塔"
              : item.supplier.slice(0, 4) == "零跑汽车"
              ? "灵隐寺"
              : item.supplier;
          item.contactStateVal =
            item.contactState == null ? "未联系" : "已联系";
          item.uploadTime = item.uploadTime == null ? "——" : item.uploadTime;
          item.firstContactTime =
            item.firstContactTime == null ? "——" : item.firstContactTime;
        });
      });
    },
    // 查看数据详情
    getItemDetail(row) {
      localStorage.setItem("id", row.id);
      console.log("row.id" + row.id);
      this.$router.push({
        name: "HomeDetail",
        path: "/home/detail",
        params: { id: row.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
</style>
